@import "../scss/all";

.SwipeableCard {
  position: relative;
  max-width: 18rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 6.5rem;
  
  @include min-width(bp(s)) {
    max-width: 22rem;
  }
}

.SwipeableCardList {
  position: relative;
  width: 100%;
  padding-bottom: 150%;
}

.SwipeableCardItem {
  position: absolute;
  border-radius: 10px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-size: cover;
  background-repeat: no-repeat;
  margin: auto;
  transition: opacity 350ms linear;
  opacity: 0;

  &:last-of-type {
    opacity: 1;
    z-index: 1;
  }

  > img {
    width: 100%;
    height: auto;
    display: none;
  }
}

.SwipeableCardFooter {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.iconButton {
  background-color: transparent;
  border: 1px solid rgba(#003B5C, 0.1);
  border-radius: 50%;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: border-color 0.2s;
}

.iconButton:hover {
  border-color: rgba(#003B5C, 0.5);
}

.iconButton span {
  display: inline-block;
}

.iconX {
  position: relative;
  width: 24px;
  height: 24px;
}

.iconX::before,
.iconX::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 1px;
  background-color: #003B5C;
  transform: translate(-50%, -50%) rotate(45deg);
}

.iconX::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.SwipeableCardItemLeft {
  opacity: 0 !important;
  transform: translate3d(-20%, 0, 0);
  transition: opacity $base-transition-speed linear 100ms,
              transform $base-transition-speed linear 100ms;
}

.SwipeableCardItemRight {
  transition: opacity $base-transition-speed linear 100ms,
              transform $base-transition-speed linear 100ms;
  opacity: 0 !important;
  transform: translate3d(20%, 0, 0);
}

