@import "../scss/all";
@import "../Button/Button.module.scss";

.orderHistoryContainer {
    position: relative;
    z-index: -1;
    top: 50px;
}
// active order state
.activeOrderContainer {
    width: 100%;
    height: 155px;
    box-shadow: 0px 2px 10px rgba(208, 207, 214, 0.5);
    background-color: transparent;
    border-radius: 6px;
    text-align: center;
    position: relative;
    overflow: hidden;
    z-index: 5;
    .activeContent {
        display: inline-block;
        height: 155px;
        margin: 0 auto;
        width: 100%;
        .orderImgDiv {
            width: 30%;
            height: 155px;
            overflow: hidden;
            float: left;
            @media (max-width: 374px) {
                width: 25%;
            }
            @media (min-width: 1024px) {
                width: 20%;
            }
            @media (min-width: 1536px) {
                width: 25%;
            }
            .orderImg {
                width: 11.2rem;
                position: relative;
                top: -2px;
                left: -80px;
                @media (max-width: 320px) {
                    left: -95px;
                }
                @media (min-width: 1536px) {
                    left: -20px;
                }
            }
        }
        .orderTextDiv {
            float: left;
            width: 60%;
            margin: 1.5rem 1rem 1rem 1rem;
            text-align: left;
            @media (min-width: 1024px) {
                float: left;
                width: 70%;
                position: relative;
                top: 3px;
                .orderText {
                    position: relative;
                    top: 30px;
                }
            }
            h3 {
                font-family: "proxima-nova", sans-serif;
                font-weight: bold;
                font-size: 16px;
                letter-spacing: .27px;
                margin-bottom: 0rem;
            }
            h4 {
                font-family: "proxima-nova", sans-serif;
                font-weight: normal;
                font-size: 14px;
                color: rgb(114, 113, 122);
                padding-bottom: 1rem;
            }
            .btnDiv {
                float: left;
                width: 100%;
                @media (min-width: 1024px) {
                    float: right;
                    width: 40%;
                    position: relative;
                    top: -25px;
                }
                @media (min-width:1536px) {
                    left: -15px;
                }
            }
        }
        
    }
}


// no order history state
.noOrderHistory {
    position: relative;
    top: -431px;
    z-index: 1;
    @media (min-width: 1536px) {
        width: 60%;
        margin: 0 auto;
        top: -288px;
    }
    .noOrderImgDiv {
        .noOrderImg {
            position: relative;
            left: -127px;
            @media (max-width: 320px) {
                left: -164px;
            }
            @media (min-width: 512px) {
                left: -107px;
            }
            @media (min-width: 768px) {
                left: -85px;
            }
            @media (min-width: 1536px) {
                left: -115px;
            }
        }
    }
    .noOrderTextDiv {
        p {
            font-family: "proxima-nova", sans-serif;
            font-size: 14px;
            font-weight: normal;
            color: rgb(114, 113, 122);
            line-height: 1.5rem;
            text-align: center;
            padding: 1.5rem 1rem;
        }
    }
}

// order items
.OrderItem {
    margin-top: $base-gutter * 2;
    margin-bottom: $base-gutter;
    position: relative;
    top: 4rem;
}
.OrderItemWrapper {
    border-bottom: rem(1) solid color(lighter);
    padding-bottom: $base-gutter;
    margin-bottom: $base-gutter;
    &:last-of-type {
      border-bottom: 0;
    }
}
.OrderItemInner {
    display: flex;
    width: 100%;
    padding: 1rem 0;
    border-bottom: 1px solid #D8D8D8;
    position: relative;
}
.OrderItemImage {
    margin-right: $base-gutter;
    border-radius: rem(6);
    max-width: 100%;
    height: auto;
    width: 81px;
}
.OrderItemStatus {
    font-family: $font-family-body;
    font-size: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: .7rem 0;
}
.OrderItemDate {
    font-family: $font-family-body;
    font-size: 14px;
    color: #72717a;
    padding-bottom: .2rem;
}

.ButtonDiv {
    display: flex;
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -23px;
}

.OrderTextDiv {
    display: inline-block;
}
.ButtonText {
    float: right;
    vertical-align: middle;
}