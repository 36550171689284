@import "../scss/all";

.Banner {
	background: #c7d4e4;
	display: flex;
	justify-content: space-around;
	padding: 12px 0;
	position: relative;
	box-shadow: 0px 2px 8px rgba(141, 140, 150, .5);
	width: 100%;
	z-index: 100;

	a {
		color: color(primary);
		font-weight: semi-bold;
		text-decoration: underline;
	}

	&.Red {
		background: color(error);

		a {
			color: color(white);
		}
	}
}