.ColorSelectionContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-family: 'proxima-nova', sans-serif;
  color: #003b5c;
  background-color: #F0EEEB;
  padding: 20px;
  border-radius: 8px;

  @media(min-width: 768px) {
    flex-direction: row;
  }
}

.SelectedColorSection {
  display: flex;
  gap: 0px;

  .SelectedColorInfo {
    display: flex;
    flex-direction: column;
    margin-left: 10px;

    p:nth-child(1) {
      font-weight: 700;
      font-size: 16px;
    }
  }

  @media(min-width: 768px) {
    flex-direction: column;
    margin-right: 20px;
    align-items: center;
  }
}

.SelectedColorPreview {
  width: 66px;
  height: 66px;
  border-radius: 50%;
  background-color: #FFFFFF;
  margin-bottom: 10px;
}

.ColorPalette {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  gap: 10px;
}

.ColorSwatch {
  width: 30px;
  height: 30px;
  border-radius: 2px;
  box-shadow: 1px 1px 1px 0px rgba(69, 73, 61, 0.75);
  cursor: pointer;
  border: 1px solid transparent;
  position: relative;

  &:hover {
    border-color: #003b5c;
  }

  @media(min-width: 768px) {
    width: 40px;
    height: 26px;
  }
}

.ColorSelected {
  border: 1.5px solid #003b5c;
}

.CheckmarkOverlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
  border-radius: 50%;

  &.DarkCheckmark::before {
    content: '';
    position: absolute;
    width: 6px;
    height: 12px;
    border: solid #003b5c;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    top: -1px;
    left: 5px;
  }
  
  &.LightCheckmark::before {
    content: '';
    position: absolute;
    width: 6px;
    height: 12px;
    border: solid  white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    top: -1px;
    left: 5px;
  }
}

.MobileLayout {
  height: 351px;
  justify-content: end;

  @media(min-width: 768px) {
    display: none;
  }
}

.DesktopLayout {
  display: none;
  align-items: center;
  height: 216px;

  @media(min-width: 768px) {
    display: flex;
    gap: 20px;
  }
}

.AddToListButton {
  padding: 15px 30px;
  background-color: #C7D4E4;
  color: #003B5C;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  width: 80%;
  margin: 0 auto;
  text-align: center;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: #A7BBD1;
  }

  @media(min-width: 768px) {
    margin-top: 130px;
    width: auto;
    
    &.Selected {
      margin-top: 10px;
    }
  }

  &:disabled {
    background-color: #b0b0b0;
    color: #fff;
    cursor: not-allowed;

    &:hover {
      background-color: #b0b0b0;
    }
  }
}


.MyColorList {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.ColorList {
  display: flex;
  flex-wrap: wrap;
  gap: 26px;
}

.ColorListItem {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.ColorCircle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #FFFFFF;
}

.ColorListItemInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.RemoveButton {
  color: #003B5C;
  padding: 4px 10px;
  border: none;
  background: transparent;
  text-decoration: underline;
  cursor: pointer;
}
