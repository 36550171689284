.FileUploadContainer {
  display: flex;
  flex-direction: column;
  font-family: "proxima-nova", sans-serif;
}

.ImagePreviewContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
}

.ImageWrapper {
  position: relative;
  width: 100px;
  height: 100px;
}

.ImagePreview {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.RemoveButton {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #fff;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  font-size: 16px;
  color: #000;
}

.FileUploadLabelContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.UploadButton {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #F0EEEB;
  border-radius: 10px;
  padding: 30px 0;
  cursor: pointer;
  text-align: center;
}

.UploadButtonDisabled {
  opacity: 0.5;
  pointer-events: none;
}

.UploadButtonContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.UploadIcon {
  font-size: 40px;
  background-color: #004466;
  color: #FFFFFF;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.ArrowUpIcon {
  position: relative;
}

.ArrowUpIcon::before {
  content: '';
  position: absolute;
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 6px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  top: -10px;
  left: -7px;
}

.ArrowUpIcon::after {
  content: '';
  position: absolute;
  width: 3px;
  height: 21px;
  background-color: white;
  top: -10px;
  left: .5px;
  transform: translate(-50%, 0);
}


.PlusIconDisabled {
  color: #aaa;
}

.UploadText {
  font-size: 20px;
  margin-top: 8px;
  color: #004466;
  font-weight: bold;
}

.FileTypes {
  font-size: 14px;
  margin-top: 4px;
}

.FileUploadInput {
  display: none;
}
