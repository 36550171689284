@import "../scss/all";

.Quiz {
  padding-bottom: 20vh;
  position: relative;
  touch-action: pan-x pan-y;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}

.QuizName {
  position: fixed;
  top: 20px;
  left: 30px;
  color: white;
}

.DrtgPaymentInfo {
  width: 6rem;
}

.Longform {
  .hello {
    color: blue;
  }

  h2.fw-medium.ff-body {
    font-size: 40px;
    font-family: $font-family-heading;
    font-weight: $font-weight-bold;
    text-align: right;
    margin-top: 4rem;
  }
}

.colorPickerContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  gap: 20px;
  justify-content: center;
}

.colorOption {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 80px; // Fixed height for consistency
}

.colorCircle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 8px; // Space between circle and label
}

.checkmark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  display: none; // Hide by default
}

.colorCircle.selected .checkmark {
  display: block; // Show when selected
}

.colorLabel {
  font-size: 12px;
  text-align: center;
  max-width: 100%; // Ensure label doesn't overflow
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

[role="button"],
button,
a {
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
}