@import "../scss/all";

$textarea-background: #FAF8F9;
$textarea-border-radius: rem(6);
$textarea-font-size: rem(16);
$textarea-min-height: rem(50);

@include max-width(bp(s)) {
  $textarea-min-height: rem(140);
}

.TextAreaLabel {
  font-size: $textarea-font-size;
  font-weight: $font-weight-medium;
}

.TextAreaInput {
  border: 1px solid color(tertiary);
  resize: none;
  border-radius: $textarea-border-radius;
  padding: 11px;
  background-color: transparent;
  font-size: $textarea-font-size;
  font-family: $font-family-body;
  display: block;
  width: 100%;
  margin-top: $base-gutter / 2;
  outline: 0;
  color: color(gray);
  overflow: hidden;

  &::placeholder {
    color: color(gray);
  }
  
}