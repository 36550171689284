.ff-body {
  font-family: "proxima-nova", sans-serif; }

.ff-header {
  font-family: "proxima-nova", sans-serif; }

.ff-sans-serif {
  font-family: sans-serif; }

.fw-medium {
  font-weight: 400; }

.fw-semibold {
  font-weight: 600; }

.fw-bold {
  font-weight: 800; }

.fs-18 {
  font-size: 18px; }

.fs-15 {
  font-size: 15px; }

.bg-primary {
  background-color: #003b5c; }

.bg-secondary {
  background-color: #003b5c; }

.bg-tertiary {
  background-color: #C7D4E4; }

.c-primary {
  color: #003b5c; }

.c-secondary {
  color: #003b5c; }

.c-tertiary {
  color: #C7D4E4; }

.c-confirmation {
  color: #97b1d1; }

.c-error {
  color: #A82400; }

.c-white {
  color: #FFFFFF; }

.c-gray {
  color: #43555F; }

.db {
  display: block; }

.dt {
  display: table; }

.dib {
  display: inline-block; }

.vam {
  vertical-align: middle; }

.full-width {
  display: block;
  width: 100%;
  margin: 0 auto; }

.pa {
  padding: 13px; }

.pa2 {
  padding: 26px; }

.pt {
  padding-top: 13px; }

.pt2 {
  padding-top: 26px; }

.pt3 {
  padding-top: 39px; }

.pt4 {
  padding-top: 52px; }

.pt- {
  padding-top: 6.5px; }

.pl {
  padding-left: 13px; }

.pl2 {
  padding-left: 26px; }

.pl- {
  padding-left: 6.5px; }

.pr {
  padding-right: 13px; }

.pr2 {
  padding-right: 26px; }

.pr- {
  padding-right: 6.5px; }

.pb {
  padding-bottom: 13px; }

.pb2 {
  padding-bottom: 26px; }

.pb- {
  padding-bottom: 6.5px; }

.mt {
  margin-top: 13px; }

.mt2 {
  margin-top: 26px; }

.mt3 {
  margin-top: 39px; }

.mt4 {
  margin-top: 52px; }

.mt- {
  margin-top: 6.5px; }

.mt0 {
  margin-top: 0; }

.ml {
  margin-left: 13px; }

.ml2 {
  margin-left: 26px; }

.ml- {
  margin-left: 6.5px; }

.mr {
  margin-right: 13px; }

.mr2 {
  margin-right: 26px; }

.mr- {
  margin-right: 6.5px; }

.mb {
  margin-bottom: 13px; }

.mb2 {
  margin-bottom: 26px; }

.mb4 {
  margin-bottom: 52px; }

.mb6 {
  margin-bottom: 78px; }

.mb7 {
  margin-bottom: 91px; }

.mb- {
  margin-bottom: 6.5px; }

.mb0 {
  margin-bottom: 0 !important; }

.mla {
  margin-left: auto; }

.mra {
  margin-right: auto; }

.m0 {
  margin: 0; }

.ttu {
  text-transform: uppercase; }

.tac {
  text-align: center; }

.tdu {
  text-decoration: underline; }

.nb {
  border: 0; }

.nbr {
  border-radius: 0; }

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.df {
  display: flex; }

.fdc {
  flex-direction: column; }

.fdcr {
  flex-direction: column-reverse; }

.aic {
  align-items: center; }

.jfc {
  justify-content: center; }

.jfe {
  justify-content: flex-end; }

.jfs {
  justify-content: space-between; }

.jfse {
  justify-content: space-evenly; }

.jsc {
  justify-self: center; }

.zx1 {
  z-index: 1; }

.zx2 {
  z-index: 2; }

.por {
  position: relative; }

.poa {
  position: absolute; }

.ls1 {
  letter-spacing: 0.0625rem; }

.strikethrough {
  text-decoration: line-through; }

.tdn {
  text-decoration: none; }

@media only screen and (max-width: 47.9375em) {
  .lineBreak-lg {
    display: none; } }

.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; }

.primary-link {
  cursor: pointer;
  color: #003b5c; }
  .primary-link:hover {
    text-decoration: underline; }

.secondary-link {
  cursor: pointer;
  color: #003b5c; }
  .secondary-link:hover {
    text-decoration: underline; }

.tertiary-link {
  cursor: pointer;
  color: #C7D4E4; }
  .tertiary-link:hover {
    text-decoration: underline; }

.gray-link {
  cursor: pointer; }
  .gray-link:hover {
    text-decoration: underline; }

.CalendarMonth {
  background: transparent !important; }

.field-gothic {
  font-family: "field-gothic-xxxcondensed", sans-serif;
  font-size: 75px;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 0.75px;
  text-transform: uppercase; }

.carousel {
  padding-bottom: 26px; }
  .carousel .slide {
    background-color: transparent !important; }
  .carousel .slide h3,
  .carousel .slide p {
    opacity: 0;
    transition: all 350ms linear 300ms; }
  .carousel .slide:first-of-type h3,
  .carousel .slide:first-of-type p {
    padding-left: 10%; }
  .carousel .selected h3,
  .carousel .selected p {
    opacity: 1; }
  .carousel .dot {
    width: 0.375rem !important;
    height: 0.375rem !important;
    margin-right: 13px !important;
    box-shadow: none !important;
    background-color: #FFC600 !important;
    opacity: 0.8;
    outline: 0;
    vertical-align: middle; }
  .carousel .control-dots .selected {
    width: 0.625rem !important;
    height: 0.625rem !important; }

@font-face {
  font-family: 'Utopia Std';
  src: url("../fonts/utopia-std/UtopiaStd-Regular.otf"); }

@font-face {
  font-family: "Recoleta";
  src: url("../fonts/recoleta/Recoleta-RegularDEMO.otf"); }

@font-face {
  font-family: "Optima";
  src: url("../fonts/optima/optima.ttf"); }

@font-face {
  font-family: "Gibson";
  src: url("../fonts/gibson/Gibson-Regular.ttf"); }

h1, h2, h3 {
  display: block;
  line-height: 1.25;
  font-family: "proxima-nova", sans-serif;
  font-weight: normal;
  margin-bottom: 13px; }

h3, h4 {
  font-weight: normal; }

h4 {
  font-family: "proxima-nova", sans-serif; }

.h1 {
  font-size: 1.5rem; }
  @media only screen and (min-width: 48em) {
    .h1 {
      font-size: 1.625rem; } }
  @media only screen and (min-width: 64em) {
    .h1 {
      font-size: 1.8125rem; } }
  @media only screen and (min-width: 96em) {
    .h1 {
      font-size: 2rem; } }

.h2 {
  font-size: 1.5rem; }
  @media only screen and (min-width: 48em) {
    .h2 {
      font-size: 1.5rem; } }
  @media only screen and (min-width: 64em) {
    .h2 {
      font-size: 1.5rem; } }
  @media only screen and (min-width: 96em) {
    .h2 {
      font-size: 1.5rem; } }

.h3 {
  font-size: 1.25rem; }
  @media only screen and (min-width: 48em) {
    .h3 {
      font-size: 1.25rem; } }
  @media only screen and (min-width: 64em) {
    .h3 {
      font-size: 1.3125rem; } }
  @media only screen and (min-width: 96em) {
    .h3 {
      font-size: 1.375rem; } }

.h4 {
  font-size: 0.8125rem; }
  @media only screen and (min-width: 48em) {
    .h4 {
      font-size: 0.8125rem; } }
  @media only screen and (min-width: 64em) {
    .h4 {
      font-size: 0.8125rem; } }
  @media only screen and (min-width: 96em) {
    .h4 {
      font-size: 0.875rem; } }

.Quiz {
  padding-bottom: 20vh;
  position: relative;
  touch-action: pan-x pan-y;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent; }

.QuizName {
  position: fixed;
  top: 20px;
  left: 30px;
  color: white; }

.DrtgPaymentInfo {
  width: 6rem; }

.Longform .hello {
  color: blue; }

.Longform h2.fw-medium.ff-body {
  font-size: 40px;
  font-family: "proxima-nova", sans-serif;
  font-weight: 800;
  text-align: right;
  margin-top: 4rem; }

.colorPickerContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  gap: 20px;
  justify-content: center; }

.colorOption {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 80px; }

.colorCircle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 8px; }

.checkmark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  display: none; }

.colorCircle.selected .checkmark {
  display: block; }

.colorLabel {
  font-size: 12px;
  text-align: center;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

[role="button"],
button,
a {
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent; }
