@import "../scss/all";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  -moz-osx-font-smoothing: grayscale; // sass-lint:disable-line no-vendor-prefixes
  -webkit-font-smoothing: antialiased; // sass-lint:disable-line no-vendor-prefixes
}

html {
  font-size: em(16);
}

body {
  background-color: #F9F8F7;
  font-family: $font-family-body;
  font-weight: $font-weight-medium;
  line-height: $base-line-height;
  color: #003b5c;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

* {
  box-sizing: border-box;
}

body.overflow-none {
  overflow: hidden;
}

.group {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  max-width: rem($base-mobile-width);

  @include min-width(bp(s)) {
    max-width: rem($base-tablet-width);
  }

  @include min-width(bp(m)) {
    max-width: rem($base-desktop-width);
  }

  &--slim {
    max-width: rem($quiz-width);
  }
}

*:disabled {
  cursor: not-allowed;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  appearance: none;
}

.pattern {
  background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 1) 20%,
      rgba(255, 255, 255, 1) 80%,
      rgba(255, 255, 255, 0.4) 100%
    ),
    url("../images/bgpattern.svg") left top repeat-y,
    url("../images/bgpattern.svg") right top repeat-y;
  min-height: 100vh;

  @include max-width(bp(s)) {
    background: none;
  }
}

hr {
  margin: $base-gutter * 2 auto;
  background: #E5E5E5;
  border: 0;
  height: rem(1);
}

.DayPicker__horizontal {
  margin: auto;
}

.CalendarDay__selected,
.CalendarDay__selected:hover {
  background-color: color(tertiary);
}

.DayPickerKeyboardShortcuts_show__bottomRight::before,
.DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
  border-right-color: color(tertiary);
}

.already-have-account {
  font-size: 13px;
}

span.link {
  text-decoration: underline !important;
  font-weight: normal !important;
}