@import '../scss/all.scss';

.Login {
  h2 {
    font-family: "field-gothic-xxxcondensed", sans-serif;
    font-size: 75px;
    font-style: normal;
    font-weight: 800;
    letter-spacing: 0.75px;
    text-transform: uppercase;
  }
  p {
    margin-top: -1rem;
    font-weight: normal;
  }
}

.ForgotPassword {
  color: color(tertiary);
  a {
    color: color(black);
    font-size: em(13);
  }
}

.SignIn {
  color: color(black);
  font-size: 13px;
  text-align: left;
  a {
    color: color(black);
  }
}