@import '../scss/all.scss';

.Auth {
  padding-bottom: 40vh;
}

.AuthSectionHeader {
  font-family: "field-gothic-xxxcondensed", sans-serif;
  font-size: 75px;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 0.75px;
  text-transform: uppercase;
}

.SignIn {
  color: color(black);
  a {
    color: color(black);
  }
}