.Callout {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 0;
  position: relative;
  color: #003b5c;

  &.full-screen {
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;

    .CalloutFullScreen {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100vh;
      background-size: cover;
      background-position: center;
      padding: 4rem;
      text-align: center;
      color: white;

      .CalloutTitle {
        font-family: "field-gothic-xxxcondensed", sans-serif;
        font-size: 75px;
        font-style: normal;
        font-weight: 800;
        letter-spacing: 0.75px;
        text-transform: uppercase;
      }

      .CalloutWrapper {
        border-radius: 10px;
        max-width: 800px;
        margin-right: 0;
        flex: none;

        p {
          font-size: 42px;
          text-align: center;
        }

        p:nth-of-type(1) {
          display: none;
        }

        p:last-child {
          font-size: 18px;
        }
      }

      .CalloutWelcomeWrapper {
        display: flex;
        justify-content: left;
        width: 100%;

        .CalloutWelcomeTitle {
          font-family: "field-gothic-xxxcondensed", sans-serif;
          font-size: 75px;
          font-style: normal;
          font-weight: 800;
          letter-spacing: 0.75px;
          text-transform: uppercase;
        }
      }

      @media (min-width: 768px) {
        justify-content: center;
      }
    }
  }

  .NavBar {
    display: flex;
    width: 1394px;
    height: 64px;
    padding: 0px 652.481px 6.975px 641.378px;
    align-items: center;
    flex-shrink: 0;
    top: 10px;
    border-radius: 10px;
    background: rgba(205, 201, 187, 0.30);
    backdrop-filter: blur(15px);

    .logo {
      width: 130px;
      height: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      cursor: pointer;
      left: 50%;
      margin-left: -60px;
    }
  }

  .CalloutWrapper {
    flex: 1;
    padding: 2rem;
    background: transparent;
    box-shadow: none;
    text-align: center;
  }

  .CalloutTitle {
    font-family: "field-gothic-xxxcondensed", sans-serif;
    font-size: 75px;
    font-style: normal;
    font-weight: 800;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    line-height: 1;

    @media (min-width: 768px) {
      margin-bottom: 0.5rem;
    }
  }
  
  .CalloutDescription p {
    text-align: center;
    font-family: "proxima-nova", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.08px;
    max-width: 400px;
    margin-bottom: 1rem;
  
    strong {
      font-weight: bold;
    }
  }
  
  button {
    display: block;
  }

  .CalloutImage {
    height: 56vh;
    background-size: cover;
    background-position: unset;
    width: 100%;
  }
  
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    &.full-screen {
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      .CalloutFullScreen {
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        padding-right: 0;

        .CalloutTitle {
          max-width: 400px;
          text-align: center;
        }
      }

      .CalloutWrapper {
        text-align: left;
        max-width: 400px;

        p:nth-of-type(1) {
          display: block !important;
        }
      }
    }

    .NavBar {
      background-color: #F1EFE94D;
      position: absolute;
      width: 97%;
      border-radius: 10px;
      left: 1.5%;

      .logo {
        width: 120px;
        height: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        cursor: pointer;
        left: 44%;
        margin-left: -60px;
      }
    }

    .CalloutWrapper {
      padding: 4rem;
      text-align: center;

      p {
        margin: 0 auto;
        margin-bottom: 1rem;
      }
    }

    .CalloutImage {
      background-position: center;
      flex: 1;
      height: 100vh;
    }

    button {
      display: block;
      width: 100%;
      max-width: 300px;
    }
  }
}

.NavBar {
  position: fixed;
  top: 0;
  z-index: 1000;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
