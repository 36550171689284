@import "../scss/all";

$input-font-size: em(16);

.DropdownWrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 0.5rem;

  &.horizontal {
    flex-direction: row;
  }

  &.vertical {
    flex-direction: column;
  }
}

.DropdownLabel {
  position: absolute;
  top: 50%;
  left: 0.8rem;
  font-size: $input-font-size;
  pointer-events: none;
  transform: translateY(-50%);
  transition: 0.2s ease all;

  &.HeightDropdownLabel {
    position: relative;
    top: 0;
    left: 0;
    margin-top: 10px;
  }
}

.Dropdown {
  appearance: none;
  background: color(input-background);
  border: 0;
  border-radius: 5px;
  color: color(primary);
  display: block;
  font-family: $font-family-body;
  font-size: $input-font-size;
  height: 48px;
  outline: 0;
  padding-left: 1rem;
  width: 100%;
  padding-top: 5px;

  &.HeightDropdown {
    padding-top: 0;
    padding-bottom: 0;
  }

  &.DenimDropdown {
    padding-top: 10px;
  }

  &.NoTitle {
    padding-top: .6rem;
  }

  &:focus {
    border: rem(1) solid color(tertiary);
  }

  &:focus + .DropdownLabel,
  &:not([value=""]) + .DropdownLabel {
    top: 0.8rem;
    font-size: rem(10);
  }
}

.HeightDropdown {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.HeightUnitLabel {
  font-size: $input-font-size;
  color: color(primary);
}
