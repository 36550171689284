@import "../scss/all";


.FormInput {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.FormInputLabel {
  font-family: "proxima-nova", sans-serif;color: #003B5C;
  font-size: 18px;
  font-weight: 400;
  margin-top: 3px;
  margin-left: 2px;
  
}

.FormCheckboxLabel {
  margin-left: 30px;
  margin-bottom: -20px;
}

.FormInputField {
  border: rem(0) solid transparent;
  border-radius: 5px;
  color: #003b5c;
  background-color: color(input-background);
  font-family: $font-family-body;
  font-size: 16px;
  height: 42px;
  height: 44px;
  padding: 6px 14px;

  &.NoBorderBottom {
    border-bottom: 0;
  }

  &::placeholder {
    opacity: 0;
  }
  
  &:hover {
    border: rem(1) solid rgba(0, 59, 92, 0.25);
  }

  &:focus {
    border: rem(1) solid rgba(0, 59, 92, 0.25);
  }

  &.isReadOnly {
    &:hover {
      border: 0;
    }
  
    &:focus {
      border: 0;
    }
  }

  &.textAreaStyles {
    height: 10rem;
  }
}

.FormInputPasswordToggle {
  position: absolute;
  top: 2rem;
  right: 20px;
  cursor: pointer;
  height: 2rem;
  color: #003b5c;
}

.FormInputErrorField {
  border: rem(1) solid color(error);
}

.FormInputErrorIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: $base-gutter;
}

.FormInputErrorMessage {
  display: block;
  color: color(error);
  margin-top: $base-gutter / 2;
  font-weight: $font-weight-semibold;
  font-size: em(13);
  letter-spacing: .4px;
}

.FormInputAdditionalCopy {
  color: color(gray);
  font-size: em(13);
  margin-top: .3rem;
  order: 3;
  text-align: left;
}
