@font-face {
  font-family: 'Utopia Std';
  src: url('../fonts/utopia-std/UtopiaStd-Regular.otf');
}

@font-face {
  font-family: "Recoleta";
  src: url('../fonts/recoleta/Recoleta-RegularDEMO.otf');
}

@font-face {
  font-family: "Optima";
  src: url('../fonts/optima/optima.ttf');
}

@font-face {
  font-family: "Gibson";
  src: url('../fonts/gibson/Gibson-Regular.ttf');
}


h1, h2, h3 {
  display: block;
  line-height: $line-height-header;
  font-family: $font-family-heading;
  font-weight: normal;
  margin-bottom: $base-gutter;
}

h3, h4 {
  font-weight: normal;
}

h4 {
  font-family: $font-family-body;
}


.h1 {
  @include font-sizes($h1-font-size-s, $h1-font-size-l);
}

.h2 {
  @include font-sizes($h2-font-size-s, $h2-font-size-l);
}

.h3 {
  @include font-sizes($h3-font-size-s, $h3-font-size-l);
}

.h4 {
  @include font-sizes($h4-font-size-s, $h4-font-size-l);
}