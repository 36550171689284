$font-default-size: 16;
$font-family-heading: "proxima-nova", sans-serif;
$font-family-body: "proxima-nova", sans-serif;
$font-weight-thin: 100;
$font-weight-light: 300;
$font-weight-medium: 400;
$font-weight-semibold: 600;
$font-weight-bold: 800;

$line-height-header: 1.25;

$base-font-size: em($font-default-size);
$base-line-height: 1.4;

$h1-font-size-s: 24;
$h1-font-size-l: 32;

$h2-font-size-s: 24;
$h2-font-size-l: 24;

$h3-font-size-s: 20;
$h3-font-size-l: 22;

$h4-font-size-s: 13;
$h4-font-size-l: 14;
