@import '../scss/all';

$button-border-radius: rem(26);
$button-font-size: em(15);
$button-height: rem(50);
$button-width: rem(100);
$button-vertical-padding: rem(20);
$button-rounded-size: rem(55);
$button-radio-height: rem(50);
$button-radio-border-width: rem(1);
$button-preview-image-height: rem(300);
$button-preview-image-height-sm: rem(160);
$button-preview-title-height: rem(40);
$button-preview-font-size: rem(14);
$button-preview-border: rem(3);

.Button {
  align-items: center;
  appearance: none;
  background: inherit;
  border-radius: 100px;
  border: 0;
  box-shadow: none;
  color: color(white);
  cursor: pointer;
  pointer-events: initial;
  display: flex;
  font-family: $font-family-body;
  font-size: $button-font-size;
  font-weight: 400;
  justify-content: center;
  margin: 0 auto;
  min-height: $button-height;
  max-width: 248px;
  outline: 0;
  letter-spacing: em(1);
  padding: 0 $button-vertical-padding;
  position: relative;
  text-align: center;
  transition: transform $base-transition-speed ease-in-out,
    border-color $base-transition-speed ease-in-out,
    background-color $base-transition-speed ease-in-out;
  -webkit-tap-highlight-color: transparent;

  .Button.ActiveState {
    border-color: color(tertiary);
    background-color: color(tertiary);
    color: color(white);
  }

  &:disabled {
    background-color: color(disabled);
    color: color(gray);

    .Arrow {
      display: none !important;
    }
  }

  &:hover {
    .Arrow {
      @include min-width(bp(s)) {
        display: inline-block;
        opacity: 1;
        transition: all 150ms;
      }
    }
  }
}

.Arrow {
  display: none;
  opacity: 0;
  margin-right: -18px;
  padding-left: 18px;

  @include min-width(bp(s)) {
    transition: all 150ms;
  }
}

.requiredQuestion {
  color: color(primary);
  margin-bottom: 1rem;
  text-align: center;
}

// Color Variations
.ButtonPrimary {
  background-color: color(primary);
}

.ButtonSecondary {
  background-color: color(secondary);
  color: color(black);
}

.ButtonTertiary {
  background-color: color(tertiary);
  color: color(primary);

  &:not(:disabled):hover {
    background: #B1C9E6;
  }
}

.ButtonWhite {
  background-color: color(white);
}

.ButtonSmall {
  min-height: calc(#{$button-height} / 1.5);
}

.ButtonInner {
  text-align: center;
  font-family: "proxima-nova", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.32px;
}

.ButtonCount {
  justify-content: space-evenly;
  align-items: center;
  padding-left: 0;
  padding-right: 0;
}

.ButtonCountText {
  width: 35%;
  min-height: calc(#{$button-height} / 1.4);
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid rgba(#d8d8d8, 0.25);
  font-size: em(20);
}

.ButtonClear {
  background-color: none;
}

.ButtonInverted {
  color: color(primary);
  background-color: transparent;
  border: 1px solid color(primary);
}

.ButtonWhiteInverted {
  color: color(white);
  background-color: color(transparent);
  border: 2px solid color(white);
  font-weight: bold;
  font-size: 1.3rem;

  &:hover {
    background-color: color(white);
    color: #003b5c;
  }
}

.Back {
  color: color(primary);
  width: fit-content;
}

.ButtonRadio {
  background-color: color(transparent);
  border: 1px solid rgba(0, 59, 92, 0.25);
  border-radius: 6px;
  color: color(primary);
  min-height: rem(40);
  text-transform: none;
  letter-spacing: normal;

  &:active {
    background-color: #003B5C;
    color: color(white);
    border: 0px solid;
  }

  &.SubscriptionSelector {
    min-width: 350px;
  }
}

.LeftAlign {
  margin: 0;
}

.ButtonInput {
  display: none;

  &:focus {
    ~.SuggestedItem {
      background-color: color(tertiary);
    }
  }

  &:checked {
    ~.ButtonRadio {
      background-color: #003B5C;
      color: color(white);
      border: 0;

      &.Sizes {
        background-color: #003B5C;
        color: color(white);
        border: 0;
      }
    }

    ~.SuggestedItem {
      background-color: color(white);
    }

    ~.ButtonPreviewImage {
      label {
        border-radius: 10px;
        box-shadow: 0px 0px 10px 2px rgba(63, 61, 58, 0.50);
        position: relative;
        
        &::after {
          content: '';
          position: absolute;
          bottom: 5px;
          right: 5px;
          width: 20px;
          height: 20px;
          background-color: white;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
    
        &::before {
          content: '';
          position: absolute;
          bottom: 10px;
          right: 12px;
          width: 5px;
          height: 10px;
          border: solid #003B5C;
          border-width: 0 1.5px 1.5px 0;
          transform: rotate(45deg);
          z-index: 1;
        }

        @media (min-width: 768px) {
          &::after {
            bottom: 10px;
            right: 10px;
            width: 25px;
            height: 25px;
          }

          &::before {
            bottom: 17px;
            right: 20px;
            width: 5px;
            height: 10px;
          }
        }

        &.BrandPreviewImage {
          border: 1.5px solid #003B5C;
          box-shadow: none;
          
          &::after {
            display: none;
          }

          &::before {
            display: none;
          }
        }

        @media (min-width: 768px) {
          &::after {
            top: 220px;
            right: 0.5rem;
            z-index: 1;
            width: 22px;
            height: 22px;
          }

          &::before {
            top: 225px;
            right: 16px;
            width: 4px;
            height: 9px;
            z-index: 2;
          }
        }
      }

      .ButtonPreviewTitle {
        text-decoration: underline;
      }

      .CheckMark {
        background-color: color(tertiary);
        border-color: color(tertiary);
        border-radius: 50%;
        display: block;
        top: 1rem;
        right: 1rem;
        left: auto;
        height: 1.5rem;
        width: 1.5rem;

        &::after {
          display: block;
        }
      }
    }
  }

  @include min-width(bp(s)) {
    ~.SuggestedItem {
      min-width: 219px;
    }

    &:checked {
      ~.ButtonPreviewImage {
        .CheckMark {
          top: 1.2rem;
          right: 1.2rem;
          height: 2rem;
          width: 2rem;

          &::after {
            left: 10px;
            top: 5px;
            width: 8px;
            height: 14px;
          }
        }
      }
    }
  }
}

.ButtonPreviewImage {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 0.7rem;
  position: relative;

  label {
    border-radius: 10px;
  }

  .CheckMark {
    display: none;
  }

  .ButtonRadio {
    &:hover {
      border: none;
    }

    &:active {
      border: none;
    }
  }
}

.BrandPreviewImage {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  margin: 0;
  width: 100%;
  height: 100%;

  .CheckMark {
    display: none;
  }

  .ButtonRadio {
    &:hover {
      border: none;
    }

    &:active {
      border: none;
    }
  }

  // .ButtonPreviewTitle {
  //   display: none;
  // }

  &.ColorPreviewImage {

    .ButtonPreviewTitle {
      display: block;
    }
  }

  &.ColorPreviewImage {

    .ButtonPreviewTitle {
      display: block;
    }
  }

  &.ThemeBoxPreviewImage {
    height: auto;
    margin-top: -1rem;
    margin-bottom: -1rem;

    label {
      height: 160px !important;
      width: auto !important;
      box-shadow: none !important;
      border-radius: 0 !important;
  
      input:checked + & {
        border: 1px solid #003B5C;
      }
    }

    @media (min-width: 768px) {
      margin-top: auto;
      margin-bottom: auto;

      label {
        height: 253px !important;
        width: 260px !important;
        box-shadow: none !important;
        border-radius: 0 !important;
      }
    }
  }
}


.AspectRatio {
  padding-top: calc(var(--aspect-height) / var(--aspect-width) * 100%);
  width: 100%;
  position: relative;
  min-height: 0;
  width: 100%;
  height: auto;
  position: relative;
  
  &:active {
    background-color: transparent;
  }
}

.ColorPreviewImage {
  border: 0;
  min-height: 0;
  padding: 2rem 14% 1.5rem;

  &:checked {
    border: 0;
  }

  label {
    border-radius: 30%;
    min-height: 1rem;
    min-width: 1rem;
    box-shadow: none !important;
    &::after {
      width: 18px !important;
      height: 18px !important;
      background-color: transparent !important;
      border: 1px solid #003B5C;
      top: -20px !important;
      right: -10px !important;
    }
    &::before {
      top: -16px !important;
      right: -3px !important;
      width: 4.5px !important;
      height: 8px !important;
    }
  }
  
  input:checked + & {
    background-color: #F0EEEB;
    border-radius: 10px;
  }

  .ButtonPreviewTitle {
    text-align: center;
  }

  @media (min-width: 768px) {
    label {
      &::after {
        width: 18px !important;
        height: 18px !important;
        background-color: transparent !important;
        border: 1px solid #003B5C;
        top: -16px !important;
        right: -8px !important;
      }

      &::before {
        top: -12px !important;
        right: -1px !important;
        width: 4.5px !important;
        height: 8px !important;
      }
    }

    .AspectRatio {
      padding-top: calc(1 / 1.12 * 100%);
    }
  }
}

.ButtonPreviewTitle {
  display: block;
  font-size: 1rem;
  font-weight: normal;
  padding: 8px 0 5px 0;
}

.CustomCheckbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-family: $font-family-body;
  font-size: 18px;
  font-weight: normal;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked {
      ~.CheckMark {
        

        &::after {
          display: block;
        }
      }
    }
  }
}

.CheckMark {
  border-radius: 0.5rem;
  position: absolute;
  top: 0;
  left: 0;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 30px;
  background: #F0EEEB;

  &::after {
    content: '';
    position: absolute;
    display: none;
    left: 9px;
    top: 6px;
    width: 4px;
    height: 8px;
    border: solid #003B5C;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}

.ButtonText {
  font-size: 14px;
  letter-spacing: 1px;
  color: color(primary);
  margin: 0.7rem 0;
  border: none;
  background: none;
  cursor: pointer;
}

.ButtonTextBlack {
  font-family: $font-family-body;
  font-weight: $font-weight-semibold;
  font-size: 14px;
  letter-spacing: 1px;
  color: color(black);
  margin: 0.7rem 0;
  border: none;
  background: none;
  cursor: pointer;
  text-decoration: underline;
  text-transform: none;
}

.ButtonNavDark {
  font-family: $font-family-body;
  font-weight: $font-weight-bold;
  font-size: 16px;
  letter-spacing: 0.53;
  color: color(white);
  margin: 0.7rem 0;
  background: none;
}

.SliderContainer {
  position: relative;
  margin: 20px 0;
  text-align: center;
  width: 100%;
}

.Slider {
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  background: #f0f0f0;
  border-radius: 4px;
  outline: none;
  padding: 0;
  margin: 0;
  position: relative;
}

.Slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #003b5c;
  cursor: pointer;
  position: relative;
}

.Slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #003b5c;
  cursor: pointer;
  position: relative;
  top: -10px;
}

.SliderLabels {
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  color: #003b5c;
  width: 100%;
  position: relative;
  top: 20px;
}

.SliderLabel {
  flex: 1;
  text-align: center;
  position: relative;
}

.SliderLabel:first-child {
  text-align: left;
}

.SliderLabel:last-child {
  text-align: right;
}
.RangeSlider {
  width: 100%;
  height: 8px;
}

.RangeSliderTrack {
  height: 6px;
  background: #e0e0e0; // Light gray for outer tracks
}

.RangeSliderTrackSelected {
  background: #003057; // Blue color for the selected (middle) track
}

.RangeSliderThumb {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #003b5c;
  cursor: pointer;
  position: relative;
  top: -8px;

  &:focus {
    outline: none;
  }
}

.SliderContainer {
  padding: 20px 10px;
}

.SliderLabels {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  span {
    color: #524939;
  }
}

.SliderValueDisplay {
  text-align: center;
  margin-top: 10px;
  font-weight: bold;
}
.buttprevimage{
  
  &:active{
    outline: none !important;}
  }

  * {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
  }