@import '../scss/all';

.flowOptionContainer {
  font-family: 'proxima-nova', sans-serif;
  position: relative;
  max-height: 220px;
  text-align: center;
  cursor: pointer;

  .flowOption {
    padding: 20px 0;
    width: initial;
    margin: 0 auto;
    margin-bottom: 25px;
    border-radius: 5px;
    border: 1px solid #E5E2DE;

    @include min-width(bp(s)) {
      padding: 40px 0;
    }
  }

  .Icon {
    height: 100px;
  }

  Button {
    background-color: transparent;
    border: rem(1) solid color(light-gray);
    border-radius: 6px;
    color: #003B5C;
    min-height: rem(50);
    min-width: 12rem;
    position: relative;
    text-transform: none;
    letter-spacing: normal;

    &:hover {
      border: rem(1) solid color(tertiary);
    }

    @include min-width(bp(s)) {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  &:has(.selectedFlow) h4 {
    font-weight: 600;
  }
}

.questionTitle {
  font-family: 'proxima-nova', sans-serif;
  font-size: 21px;
  color: #003B5C;
}

.deliveryGrid {
  margin-bottom: 2rem;
}

.storeSelect {
  appearance: none;
  background: #F0EEEB;
  border: 0;
  border-radius: 5px;
  font-size: 16px;
  color: #003b5c;
  padding: 0 16px;
  min-height: 3rem;
  outline: 0;
  width: 80%;
}

.selectedFlow {
  background-color: #F0EEEB;
  h4 {
    font-weight: 800;
  }
}

.subscriptionOptions {
  display: flex;
  flex-wrap: wrap;
  row-gap: 16px;
  column-gap: 16px;

  .subscriptionOption {
    border: 1px solid rgba(#003B5C, .1);
    border-radius: 5px;
    background-color: transparent;
    color: #003B5C;
    font-size: 16px;
    padding: 12px 20px 12px 20px;
  }

  &:nth-child(-n+0) {
    margin-bottom: 16px;
  }

  .selectedOption {
    background-color: #003B5C;
    color: #FFFFFF;
  }
}
