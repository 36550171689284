.ProgressBarWrapper {
  display: flex;
  justify-content: flex-start;
  font-family: "proxima-nova", sans-serif;
  font-weight: 400;
  text-align: center;
  font-size: 14px;
  height: 10px;
  gap: 0;
  
  @media (min-width: 768px) {
    justify-content: space-evenly;
    gap: 20px;
  }
}

.ProgressBarSegment {
  display: inline-block;
  height: 10px;
  background: rgba(75, 128, 42, 0.15);
  width: 25%;
  margin-right: 0;
  border-radius: 0;
  
  &:first-child {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  
  &:last-child {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  
  @media (min-width: 768px) {
    width: 22%;
    border-radius: 2px;
  }
}

.ProgressBarFill {
  background: #3E731F;
  height: 100%;
  transition: width 0.3s ease;
  margin-bottom: 10px;
  border-radius: 0;
  
  .ProgressBarSegment:first-child & {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  
  .ProgressBarSegment:last-child & {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  
  @media (min-width: 768px) {
    border-radius: 2px;
  }
}

.ProgressBarSteps {
  display: flex;
  justify-content: space-evenly;
}