@import "../scss/all";

.SectionHeader {
  h2 {
    font-size: 1.5rem;
    color: #003B5C;
  }

  h2.newHeader {
    font-family: "field-gothic-xxxcondensed", sans-serif;
    font-size: 75px;
    font-style: normal;
    font-weight: 800;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    color: #003B5C;
  }

  p {
    color: #003B5C;
    font-weight: normal;
    max-width: 32rem;
  }
}

