@import "../scss/all";

$button-border-radius: rem(26);
$button-font-size: em(16);
$button-height: rem(50);

.accountManagementForm {
  margin-bottom: 10rem;

  h2 {
    color: color(black);
    font-size: 24px;
    color: color(black);
  }
}

.linkCTA {
  color: color(tertiary);
  font-family: $font-family-body;
  font-weight: $font-weight-bold;
  margin-bottom: 2rem;
  cursor: pointer;
}

.cardInfo {
  font-family: $font-family-body;
  font-weight: $font-weight-bold;
  letter-spacing: 0.27px;
  line-height: 24px;
  margin-left: 1rem;
  text-transform: uppercase;
}

.editField {
  display: flex;
  color: color(tertiary);
  font-size: $button-font-size;
  letter-spacing: 0.5px;
  margin: 0 auto;
  text-decoration: none;
  width: fit-content;
  cursor: pointer;

  &.Border {
    color: #003b5c;
    border: 1px solid #003b5c;
    border-radius: $button-border-radius;
    padding: 0.4rem 1.1rem;
    opacity: .9;
    &:hover {
      opacity: 1;
    }
  }
}

.hr {
  opacity: 0.25;
  background: #d8d8d8;
  border: 1px solid #979797;
}

.check {
  float: right;
}

.paymentMethod {
  margin-top: 1.3rem;
}

.storeName {
  font-family: $font-family-body;
  font-weight: $font-weight-bold;
  letter-spacing: 0.27px;
  line-height: 24px;
  text-transform: uppercase;
}

.storeDistance {
  font-family: $font-family-body;
  letter-spacing: 0;
  line-height: 22px;
}

.errorMessage {
  color: #DA0229;
}

.formErrorMessages {
  margin-bottom: 20px;
}